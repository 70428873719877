<template>
    <div class="title">Ничего не найдено</div>
</template>

<script>
export default {
    name: 'UiNotFound',
    
}
</script>


<style scoped>
    .title{
        margin-top: 30px;
        font-family: 'Intro-Bold-Alt';
        font-size: 20px;
        color: #fff;
    }
</style>